
import React, { useState } from 'react';
import "./terms.css";

function Terms(props) {
  
  return (
    <div className='terms-container' id="terms">
      <div className='page-title'>Syarat dan Ketentuan Penggunaan</div>
      <div className='page-subtitle'>-</div>
      <div className='page-paragraph'>
      -
      </div>
    </div>
  );
}

export default Terms;