
import React, { useState } from 'react';
import "./privacy.css";

function Privacy(props) {
  
  return (
    <div className='privacy-container' id="privacy">
      <div className='page-title'>Kebijakan Privasi</div>
      <div className='page-subtitle'>-</div>
      <div className='page-paragraph'>
      -
      </div>
    </div>
  );
}

export default Privacy;