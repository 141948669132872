
import React, { useState } from 'react';
import "./tentang.css";

function Tentang(props) {
  
  return (
    <div className='tentang-container' id="tentang">
      <div className='page-title'>Tentang</div>
      <div className='page-subtitle'>Apa itu C-Food?</div>
      <div className='page-paragraph'>
      -
      </div>

      <div className='page-subtitle'>Tim C-Food</div>
      <div className='page-paragraph'>
      -
      </div>
    </div>
  );
}

export default Tentang;