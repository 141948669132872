
import React, { useState } from 'react';
import "./bantuan.css";

function Bantuan(props) {
  
  return (
    <div className='bantuan-container' id="bantuan">
      <div className='page-title'>Bantuan</div>
      <div className='page-subtitle'>Frequently Asked Questions</div>
      <div className='page-paragraph'>
      -
      </div>
      <div className='page-subtitle'>Guides</div>
      <div className='page-paragraph'>
      -
      </div>
    </div>
  );
}

export default Bantuan;