
import React, { useState } from 'react';
import "./news.css";

function News(props) {
  
  return (
    <div className='news-container' id="news">
      <div className='page-title'>News</div>
      <div className='page-subtitle'>-</div>
      <div className='page-paragraph'>
      -
      </div>
    </div>
  );
}

export default News;