import React from 'react';
import "./ulasan.css";
import bgulasan from '../../images/bg-ulasan.png';

function Ulasan() {
  const reviews = [
    {
      image: 'image1.jpg',
      name: 'Umar Faruq Robbany',
      feedback: 'C-Food sangat membantu saya menemukan makanan enak tanpa perlu antri di kantin. Aplikasinya mudah digunakan dan kurirnya cepat!',
      role: 'Mahasiswa POLBAN, 2023'
    },
    {
      image: 'image1.jpg',
      name: 'Gina Nurul Azqiah',
      feedback: 'Layanan C-Food benar-benar memudahkan saya untuk menemukan makanan sehat dan bergizi. Sangat direkomendasikan!',
      role: 'Mahasiswa POLBAN, 2023'
    },
    {
      image: 'image1.jpg',
      name: 'Farrel Zandra',
      feedback: 'Pengalaman yang luar biasa! Makanan datang tepat waktu dan sesuai dengan pesanan.',
      role: 'Mahasiswa POLBAN, 2023'
    },
    {
      image: 'image1.jpg',
      name: 'Banteng Harisantoso',
      feedback: 'Aplikasi yang sangat intuitif dan mudah digunakan. Sangat membantu di saat-saat sibuk.',
      role: 'Mahasiswa POLBAN, 2023'
    }
  ];

  return (
    <div className='ulasan-container' id="ulasan" style={{ 
      backgroundImage: `url(${bgulasan})`, 
      backgroundSize: 'contain', // Ukuran gambar sesuai konten
      backgroundRepeat: 'repeat', // Tidak mengulang gambar
      backgroundPosition: 'center' // Posisi gambar di tengah
     }}>
      <div className='section-title'>
        Ulasan dari Pengguna
      </div>
      <div className='section-detail section-card-container'>
        {reviews.map((review, index) => (
          <div className='review-card' key={index}>
            <div className='review-image'>
              <img src={review.image} />
            </div>
            <div className='review-detail'>
              <div className='review-title'>{review.name}</div>
              <div className='review-paragraph'>
                "{review.feedback}"
              </div>
              <div className='review-role'>
                ~ {review.role}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Ulasan;
