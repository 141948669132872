
import React, { useEffect, useState } from 'react';
import "./login.css";
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaStore, FaUniversity, FaUsers } from 'react-icons/fa';

function Login(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [typeLogin, setTypeLogin] = useState();

  const togglePass = () => {
    setIsOpen(!isOpen);
  };

  const toggleType = (type) => {
    setTypeLogin(type);
  };

  useEffect(() => {
    // Get the hash part of the URL (e.g., "#merchant", "#institusi", "#organisasi")
    const hash = window.location.hash.substring(1); // Remove the "#" symbol
    if (hash) {
      setTypeLogin(hash);
    } else {
      setTypeLogin("merchant");
    }
  }, []);

  
  return (
    <div className='login-container' id="login">
      <div className='login-title'>Login</div>
      <div className='login-type-list'>
        <div className={typeLogin == "merchant" && "active"} onClick={() => toggleType("merchant")}>
          <FaStore/> Merchant
        </div>

        <div className={typeLogin == "institusi" && "active"} onClick={() => toggleType("institusi")}>
          <FaUniversity/> Kampus
        </div>

        <div className={typeLogin == "organisasi" && "active"} onClick={() => toggleType("organisasi")}>
          <FaUsers/> Organisasi
        </div>
      </div>
      <div className='login-form'>
        <input type='email' placeholder='Email'></input>
        <div className='password'>
          <input input type={isOpen ? 'text' : 'password'} placeholder='Password'></input>
          <div className='input-icon' onClick={togglePass}>
            {
              isOpen ? <FaEye/> : <FaEyeSlash/>
            }
          </div>
        </div>
        <Link to="/lupa-password" className="reset-pass">Lupa Password?</Link>
        <button className='login-btn'>
          Login
        </button>
        <div className='login-register'>
          Belum punya akun? <Link to={"/register#" + typeLogin}>Daftar sekarang</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;