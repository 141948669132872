import React, { useEffect, useState } from 'react';
import "./register.css";
import { Link } from 'react-router-dom';
import { FaCaretDown, FaCaretUp, FaCross, FaDownload, FaEye, FaEyeSlash, FaGooglePlay, FaStore, FaTimes, FaUniversity, FaUsers } from 'react-icons/fa';

function Register(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRe, setIsOpenRe] = useState(false);
  const [typeRegister, setTypeRegister] = useState();
  const [campuses, setCampuses] = useState([]); // State to hold campus data
  const [searchTerm, setSearchTerm] = useState(""); // State to handle the search input
  const [filteredCampuses, setFilteredCampuses] = useState([]); // Filtered campuses based on search
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [isSelectCampus, setIsSelectCampus] = useState(false);

  const togglePass = () => {
    setIsOpen(!isOpen);
  };

  const toggleRePass = () => {
    setIsOpenRe(!isOpenRe);
  };

  const toggleType = (type) => {
    setTypeRegister(type);
  };

  const toggleOption = () => {
    setSearchTerm("");
    setFilteredCampuses([]);
  };

  const selectCampus = (id, name) => {
    setSelectedCampus(id);
    setSearchTerm(name);
    setFilteredCampuses([]);
    setIsSelectCampus(true);
  }

  useEffect(() => {
    // Get the hash part of the URL (e.g., "#merchant", "#institusi", "#organisasi")
    const hash = window.location.hash.substring(1); // Remove the "#" symbol
    if (hash) {
      setTypeRegister(hash);
    } else {
      setTypeRegister("merchant");
    }

    // Fetch campuses data from API
    fetch('https://cfood.id/api/campuses/')
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setCampuses(data.data); // Set campus data to state
          setFilteredCampuses(data.data); // Set filtered data initially to all campuses
        }
      })
      .catch(error => console.error('Error fetching campuses:', error));
  }, []);

  // Filter the campus list based on the search input
  useEffect(() => {
    const filtered = campuses.filter(campus =>
      campus.campusName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCampuses(filtered);
  }, [searchTerm, campuses]);

  // Reset selected campus and show the dropdown when the input changes
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedCampus(null); // Reset selected campus when input is modified
    setIsSelectCampus(false);
  };

  return (
    <div className='register-container' id="register">
      <div className='register-title'>Daftar</div>
      <div className='register-type-list'>
        <div className={typeRegister === "merchant" ? "active" : ""} onClick={() => toggleType("merchant")}>
          <FaStore/> Merchant
        </div>

        <div className={typeRegister === "institusi" ? "active" : ""} onClick={() => toggleType("institusi")}>
          <FaUniversity/> Kampus
        </div>

        <div className={typeRegister === "organisasi" ? "active" : ""} onClick={() => toggleType("organisasi")}>
          <FaUsers/> Organisasi
        </div>
      </div>
      {
        typeRegister === "merchant" || typeRegister === "institusi" ?
        <div className='register-not'>
          {typeRegister === "institusi" ?
          <>
            <p>
              Saat ini, registrasi untuk kampus hanya dapat dilakukan dengan menghubungi email <a href="mailto:master.cfood@gmail.com">master.cfood@gmail</a>.
            </p>
            <div className='login-register'>
              Sudah punya akun? <Link to={"/login#" + typeRegister}>Login sekarang</Link>
            </div>
          </>
          
          :
          <>
            Registrasi sebagai wirausaha/kantin hanya dapat dilakukan melalui aplikasi mobile. Silakan unduh aplikasi C-Food untuk melanjutkan.

            <div className='unduh-list'>
              <a className="btn-primary" href="https://campusfood.id/CFood.apk" style={{display: "flex", alignItems: "center", columnGap: "0.6em", width: "fit-content"}}>
                <FaDownload/> Unduh Aplikasi
              </a>

              <a className="btn-tertiary" href="#unduh" style={{display: "flex", alignItems: "center", columnGap: "0.6em", width: "fit-content"}}>
                <FaGooglePlay/> 
                <div style={{display: "flex", flexDirection: "column"}}>
                  <span style={{fontSize: "0.6em"}}>GET IT ON</span>
                  <span style={{fontWeight: "600"}}>Google Play</span>
                </div>
              </a>
            </div>

            <div className='login-register'>
              Sudah punya akun? <Link to={"/login#" + typeRegister}>Login sekarang</Link>
            </div>
          </>
          }
        </div>
        :
        <div className='register-form'>
          <input type='text' placeholder='Nama Organisasi'></input>
          <input type='text' placeholder='Singkatan Nama Organisasi'></input>
          <input type='email' placeholder='Email'></input>
          <div className='password'>
            <input type={isOpen ? 'text' : 'password'} placeholder='Password'></input>
            <div className='input-icon' onClick={togglePass}>
              {isOpen ? <FaEye/> : <FaEyeSlash/>}
            </div>
          </div>

          <div className='password'>
            <input type={isOpenRe ? 'text' : 'password'} placeholder='Konfirmasi Password'></input>
            <div className='input-icon' onClick={toggleRePass}>
              {isOpenRe ? <FaEye/> : <FaEyeSlash/>}
            </div>
          </div>

          {/* Searchable Campus Dropdown */}
          <div className='campus-select'>
            <input
              type="text"
              placeholder="Kampus"
              value={searchTerm}
              onChange={handleInputChange}
              style={searchTerm !== "" && !selectedCampus ? {borderRadius: "0.4em 0 0 0", borderBottom: "none"} : {}}
            />
            <div className='input-icon' onClick={toggleOption} style={searchTerm !== "" && !selectedCampus ? {borderRadius: "0 0.4em 0 0", borderBottom: "none"} : {}}>
              {searchTerm !== "" ? <FaTimes/> : <FaCaretDown/>}
            </div>
            {
              searchTerm != "" && !isSelectCampus &&
              <div className='campus-dropdown'>
              {filteredCampuses.length > 0 ? (
                filteredCampuses.map((campus) => (
                  <div key={campus.id} className='campus-option enable' onClick={() => selectCampus(campus.id, campus.campusName)}
                  style={{cursor: "pointer"}}>
                    {campus.campusName}
                  </div>
                ))
              ) : (
                <div className='campus-option'>Kampus tidak ditemukan</div>
              )}
            </div>
            }
            
          </div>
          
          <button className='register-btn'>
            Daftar
          </button>
          <div className='login-register'>
            Sudah punya akun? <Link to={"/login#" + typeRegister}>Login sekarang</Link>
          </div>
        </div>
      }
      
    </div>
  );
}

export default Register;
